<template>
    <div class="d-flex">
        <section class="w-50 pe-4">
            <h1 class="mb-2">Knowledge base</h1>
            <FormItem type="text" v-model="item.title" :label="$t('overview.title')" />
            <FormItem type="textarea" v-model="item.problem_description" :label="$t('form.problem_description')" />
            <FormItem type="textarea" v-model="item.solution_description" :label="$t('form.solution_description')" />
            <FormItem type="text" v-model="item.extra_information_url" :label="$t('form.extra_information_url')" />
            <FormItem type="text" v-model="item.issue_origin" :label="$t('form.issue_origin')" disabled/>
            <h1 class="mb-2 mt-4">Melding</h1>
            <pre>{{item.issue_text}}</pre>
            <h1 class="mb-2 mt-4" @click="toggleTranslation" style="cursor: pointer;">
                {{$t('overview.translation')}}
                <i :class="{'fas fa-chevron-down': showTranslation, 'fas fa-chevron-right': !showTranslation}"></i>
            </h1>
            <template v-if="showTranslation">
                <button class="btn btn-unilight save-button px-4 me-2" @click="addTranslation">Add Translation</button>
                <template v-for="(translation, index) in item.translations" :key="translation.id">
                    <FormItem type="text" v-model="translation.language_code" :label="$t('form.country_code')"/>
                    <FormItem type="text" v-model="translation.title" :label="$t('overview.title')" />
                    <FormItem type="textarea" v-model="translation.problem_description" :label="$t('form.problem_description')" />
                    <FormItem type="textarea" v-model="translation.solution_description" :label="$t('form.solution_description')" />
                    <FormItem type="text" v-model="translation.extra_information_url" :label="$t('form.extra_information_url')" />
                    <button class="btn btn-unilight delete-button px-4 me-2" @click="removeTranslation(index)">Remove Translation</button>
                    <hr/>
                </template>
            </template>

            <h1 class="mb-2 mt-4">Koppel andere issues aan deze issue</h1>
            <FormItem type="text" v-model="item.search_text" :label="$t('form.search_text')" @change="searchIssues()"/>
            <table class="table table-striped mt-2">
                <thead>
                    <tr>
                        <th>Select</th>
                        <th>ID</th>
                        <th>Issue Text</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="issue in found_issues" :key="issue.id">
                        <td>
                            <input type="checkbox" @change="toggleIssue(issue)" :checked="isIssueSelected(issue)">
                        </td>
                        <td>{{issue.id}}</td>
                        <td>{{issue.issue_text}}</td>
                    </tr>
                </tbody>
            </table>

            <h1 class="mb-2 mt-4">Gekoppelde issues</h1>
            
            <table class="table table-striped mt-2">
                <thead>
                    <tr>
                        <th>Select</th>
                        <th>ID</th>
                        <th>Issue Text</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="issue in item.children" :key="issue.id">
                        <td>
                            <input type="checkbox" @change="toggleIssue(issue)" :checked="isIssueSelected(issue)">
                        </td>
                        <td>{{issue.id}}</td>
                        <td>{{issue.issue_text}}</td>
                    </tr>
                </tbody>
            </table>

        </section>
    </div>
    <FooterButtons @prev="prev" @save="save" :disabled="isSaving" />
</template>

<script>
    import KnowledgeBaseService from '@/services/KnowledgeBaseService';
    import FooterButtons from '@/components/FooterButtons.vue';
    export default {
        name: 'HoldingDetail',
        components: {
            FooterButtons
        },
        data() {
            return {
                item: {
                    "title": "",
                    "problem_description": "",
                    "solution_description": "",
                    "extra_information_url": "",
                    "issue_origin": "",
                    "issue_text": "",
                    "search_text": "",
                    "children": [],
                    "translations": []
                },
                found_issues: [],
                isNew: true,
                isSaving: false,
                showTranslation: false
            }
        },
        methods: {
            searchIssues(){
                if (!this.item.search_text) {
                    this.found_issues = [];
                    return;
                }
                KnowledgeBaseService.search(this.item.search_text).then(response => {
                    this.found_issues = response.data;
                });
            },
            validate(){
                //Validation
                const els = document.querySelectorAll("section input[type='text']:required, section select:required, section textarea:required");
                let valid = true;
                els.forEach(el => {
                    if(!el.value){
                        el.classList.add("is-invalid");
                        if(!el.classList.contains("has-onfocus")){
                            el.addEventListener("focus", (thisEl) => {
                                thisEl.target.classList.remove("is-invalid");
                            })
                            el.classList.add("has-onfocus");
                        }
                        valid = false;
                    }
                })
                return valid;
            },
            prev(){
                this.$router.push({'name': "Knowledge Base"});
            },
            save() {
                if(!this.validate()){
                    return;
                }
                this.isSaving = true;
                KnowledgeBaseService.updateOrStore(this.item).then(response => {
                    this.$router.push({'name': "Knowledge Base"});
                }).catch(e => {
                    this.isSaving = false;
                    this.toastError(e);
                });
            },
            toggleIssue(issue) {
                const index = this.item.children.findIndex(child => child.id === issue.id);
                if (index === -1) {
                    this.item.children.push(issue);
                } else {
                    this.item.children.splice(index, 1);
                }
            },
            isIssueSelected(issue) {
                return this.item.children.some(child => child.id === issue.id);
            },
            toggleTranslation() {
                this.showTranslation = !this.showTranslation;
            },
            addTranslation() {
                this.item.translations.push({
                    id: Date.now(),
                    language_code: '',
                    title: '',
                    problem_description: '',
                    solution_description: '',
                    extra_information_url: ''
                });
            },
            removeTranslation(index) {
                this.item.translations.splice(index, 1);
            }
        },
        mounted(){
            const locationId = this.$route.params.id;
            this.isNew = locationId == 'nieuw';
            if(!this.isNew){
                KnowledgeBaseService.show(locationId).then(response => {
                    this.item = response.data;
                    this.searchIssues()
                });
            }
        }
    }
</script>